import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import useSubscription from '@/hooks/account/useSubscription';
import useWidget from '@/hooks/widgets/useWidget';

import FanHubFlowButton from '../utility/buttons/FanHubFlowButton';
import Card from '../utility/microcomponents/Card';
import CampaignStatus from '../utility/statuses/CampaignStatus';

export const FanHubWidget = () => {
  const { t } = useTranslation();
  const { widget } = useWidget({ type: 'fan-hub' });
  const { isSubscribed } = useSubscription();
  const [, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <>
      <FanHubFlowButton
        hideButton
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      />
      <Card
        className={`p10 widget-card fan-hub pos-rel text-center ${isSubscribed ? '' : 'disabled'}`}
        onClick={() => {
          if (!isSubscribed) {
            setSearchParams({ subscription: 'true' });
            return;
          }
          navigate('/fan-hub');
        }}
      >
        {widget?.fanHub?.isActive ? (
          <img src={widget?.fanHub?.imageUrl} alt="fan-hub" height={80} width={80} className="br50p mt10" />
        ) : (
          <img src={Images.widgetImages.fanHub} alt="fan-hub" height={80} className="br50p mt10" />
        )}
        {!isSubscribed && (
          <div className="pos-abs t10 r10">
            <img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />
          </div>
        )}
        <div className="d-flex mt42 pos-abs b10 footer text-left">
          <div>
            <p className="fw-bold">{t('HOMEPAGE.FAN-HUB')}</p>
            {widget?.fanHub?.isActive && <CampaignStatus status={widget?.fanHub?.isActive ? 'active' : ''} />}
            {!widget?.fanHub?.isActive && (
              <p className="text-reduced-opacity small">{t('WEBSITE-BUILDER.SET-UP-A-FAN-HUB')}</p>
            )}
          </div>
          <Button className="widget-button">
            <Icon>chevron_right</Icon>
          </Button>
        </div>
      </Card>
    </>
  );
};
