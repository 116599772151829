import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import { BetaTag } from '@/components/utility/statuses/BetaTag';
import { Images } from '@/constants/Images';
import useSubscription from '@/hooks/account/useSubscription';

import Card from '../utility/microcomponents/Card';

export const AIGeneratorWidget = () => {
  const { t } = useTranslation();
  const { isSubscribed } = useSubscription();
  const [, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  return (
    <Card
      className={`p10 widget-card ai-generator pos-rel text-center ${isSubscribed ? '' : 'disabled'}`}
      onClick={() => {
        if (!isSubscribed) {
          setSearchParams({ subscription: 'true' });
          return;
        }
        navigate('/lyra');
      }}
    >
      <img src={Images.widgetImages.aiGenerator} alt="partner-perks" height={80} className="mt10" />
      {!isSubscribed && (
        <div className="pos-abs t10 r10">
          <img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />
        </div>
      )}

      <div className="d-flex mt42 pos-abs b10 footer text-left">
        <div>
          <p className="fw-bold d-flex gap4">
            {t('NAVIGATION.AI-GENERATOR')}
            <BetaTag isGreen className="" />
          </p>
          <p className="text-reduced-opacity small">{t('Content generation')}</p>
        </div>
        <Button className="widget-button mt-auto">
          <Icon>chevron_right</Icon>
        </Button>
      </div>
    </Card>
  );
};
