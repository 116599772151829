import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import useWidget from '@/hooks/widgets/useWidget';

import SocialAdsButtons from '../utility/buttons/SocialAdsButtons';
import Card from '../utility/microcomponents/Card';

export const TikTokAdsWidget = () => {
  const { t } = useTranslation();
  const { widget } = useWidget({ type: 'tiktok-ads' });
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <SocialAdsButtons hideButton isOpenTikTok={isOpen} close={() => setIsOpen(false)} />
      <Card
        className="p10 widget-card tiktok-ads pos-rel text-center"
        onClick={() => {
          if (widget?.active === 0) {
            setIsOpen(true);
            return;
          }
          navigate('/social-ads');
        }}
      >
        {widget?.active === 0 ? (
          <img src={Images.widgetImages.socialAds} alt="tiktok-ads" height={80} className="mt10" />
        ) : (
          <img src={Images.widgetImages.tikTokAds} alt="tiktok-ads" height={80} className="mt10" />
        )}

        <div className="d-flex mt42 pos-abs b10 footer text-left">
          <div>
            <p className="fw-bold">{`${widget?.active === 0 ? t('COMMON.CREATE-AN-AD') : `${widget?.active} ${t('WIDGETS.ACTIVE')}`}`}</p>
            <p className="text-reduced-opacity small">{`${widget?.active === 0 ? t('WIDGETS.RUN-TIKTOK-ADS') : t('WIDGETS.TIKTOK-ADS')}`}</p>
          </div>
          <Button className="widget-button">
            <Icon>chevron_right</Icon>
          </Button>
        </div>
      </Card>
    </>
  );
};
