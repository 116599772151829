import React, { CSSProperties, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, ClickAwayListener, Icon, Tooltip } from '@mui/material';

import useAccountContext from '@/hooks/context/useAccountContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import useWidgets from '@/hooks/widgets/useWidgets';
import { WidgetModel, WidgetType } from '@/models/Widgets';
import WidgetsAPI from '@/network/WidgetsAPI';

import { SortableItem } from '../utility/drag-and-drop/SortableItem';
import Loading from '../utility/Loading';
import Card from '../utility/microcomponents/Card';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import AllWidgetsModal from '../utility/modals/AllWidgetsModal';
import { AIGeneratorWidget } from './AIGeneratorWidget';
import { FanHubWidget } from './FanHubWidget';
import { MetaAdsWidget } from './MetaAdsWidget';
import { MusicInsightsWidget } from './MusicInsights';
import { PartnerPerkWidget } from './PartnerPerkWidget';
import { PlaylistPitchesWidget } from './PlaylistPitchesWidget';
import { SocialInsightsWidget } from './SocialInsightsWidget';
import { TikTokAdsWidget } from './TikTokAdsWidget';

export const WidgetsComponent = () => {
  const { accountId } = useAccountContext();
  const { breakpointHit } = useBreakpoints();
  const { t } = useTranslation();
  const [items, setItems] = useState<WidgetModel[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const { widgets, refetchWidgets } = useWidgets();

  const pointerSensor = useSensor(PointerSensor);
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const sensors = useSensors(pointerSensor, keyboardSensor);

  const handleDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;
    if (active?.id && over?.id && active.id !== over.id) {
      setItems((prevItems) => {
        if (!prevItems) return [];
        const oldIndex = prevItems.findIndex((item) => item.type === active.id);
        const newIndex = prevItems.findIndex((item) => item.type === over.id);

        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id && over?.id && active.id !== over.id) {
      setItems((prevItems) => {
        if (!prevItems) return [];
        const oldIndex = prevItems.findIndex((item) => item.type === active.id);
        const newIndex = prevItems.findIndex((item) => item.type === over.id);
        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }
    updateWidgets();
  };

  const handleRemove = async (event: MouseEvent<HTMLButtonElement>, widget: string) => {
    event.preventDefault();
    event.stopPropagation();
    const newItems = items?.map((item) => item.type) || [];
    setItems(items?.filter((item) => item.type !== widget));
    updateWidgets(newItems.filter((item) => item !== widget));
  };

  useEffect(() => {
    if (widgets) {
      setItems(widgets.widgets);
      setIsLoading(false);
    }
  }, [widgets]);

  const updateWidgets = useCallback(
    async (widgets?: WidgetType[]) => {
      if (!accountId) return;
      let newItems: WidgetType[] = [];
      if (widgets) {
        newItems = widgets;
      } else {
        newItems = items?.map((item) => item.type) || [];
      }
      await WidgetsAPI.updateWidgetData({ accountId: accountId, data: { widgetTypes: newItems || [] } }).then(
        async () => {
          await refetchWidgets();
          setIsModalOpen(false);
          setIsLoading(false);
        }
      );
    },
    [accountId, items, refetchWidgets]
  );

  return (
    <div className={`${isLoading ? 'w100p' : 'w-fit-max-100p-1620'}`}>
      <AllWidgetsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        updateWidgets={(widgets) => {
          updateWidgets(widgets);
        }}
      />
      <div className="d-flex">
        <div className="mt20 d-flex mb8">
          <h4>{t('WIDGETS.WIDGETS')}</h4>
          <span className="pl8 mt6">
            <Tooltip
              arrow
              placement="top"
              open={tooltipOpen}
              title={
                <div className="p10">
                  <h4>Customise your home screen with widgets</h4>
                  <p className="small">
                    For easy access to your most-used actions, your widgets will give you updates on everything
                    important. You can add, remove or reorder them to customise your home screen.
                  </p>
                </div>
              }
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setTooltipOpen(!tooltipOpen);
                }}
                onMouseOver={() => {
                  setTooltipOpen(true);
                }}
                onMouseLeave={() => {
                  setTooltipOpen(false);
                }}
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setTooltipOpen(false);
                  }}
                >
                  <Icon className="fs-16 material-symbols-outlined cursor-pointer">info</Icon>
                </ClickAwayListener>
              </div>
            </Tooltip>
          </span>
        </div>

        {!isEditMode && items && items.length > 0 && !isLoading && (
          <Button className="icon-btn ml-auto mr-6 no-bg" onClick={() => setIsEditMode(true)}>
            <CustomIcons name="edit" className="mt8" />
          </Button>
        )}
        {isEditMode && (
          <div className="ml-auto mr0">
            <Button
              className="ml-auto d-flex"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <Icon className="pr8 fs-sm">add</Icon>
              {t('WIDGETS.ADD-WIDGET')}
            </Button>
            <Button className="ml-auto mr0 d-flex btn-white" onClick={() => setIsEditMode(false)}>
              <Icon className="material-symbols-outlined pr8 fs-sm">check_circle</Icon>
              {t('COMMON.DONE')}
            </Button>
          </div>
        )}
      </div>

      <Card
        id="home-page-widgets"
        inner={breakpointHit ? false : true}
        className={`${breakpointHit ? 'p0 no-bg flex-wrap gap4' : 'p10 overflow-scroll max-h200 gap10'} min-h190 d-flex  ${isEditMode ? 'touch-action-none' : ''} ${isLoading ? 'w100p' : 'w-fit-max-100p-1620'}`}
      >
        {isLoading && (
          <div className="centered-loading mt40">
            <Loading size="small" />
          </div>
        )}
        {items && items.length === 0 && !isLoading && (
          <div
            className="to-do-card-border widget-card pos-rel cursor-pointer min-w263"
            onClick={() => setIsModalOpen(true)}
          >
            <div className="to-do-card h100p p0">
              <Icon
                className="abs-center abs-center-xy fs-custom"
                style={{ '--customFontSize': '3rem' } as CSSProperties}
              >
                add
              </Icon>
            </div>
          </div>
        )}
        {!isLoading && (
          <DndContext
            sensors={isEditMode ? sensors : undefined}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            modifiers={breakpointHit ? undefined : [restrictToHorizontalAxis]}
          >
            {items && (
              <SortableContext items={items?.map((item) => item.type)} strategy={verticalListSortingStrategy}>
                {items?.map((widget, index) => (
                  <SortableItem
                    key={widget.type}
                    id={widget.type}
                    isDraggable={isEditMode}
                    index={index}
                    onRemove={handleRemove}
                  >
                    <>
                      <div
                        key={index}
                        className="widget-card cursor-pointer"
                        style={{ '--animation-number': index } as CSSProperties}
                      >
                        {widget.type === 'playlist-pitches' && <PlaylistPitchesWidget />}
                        {widget.type === 'partner-perks' && <PartnerPerkWidget />}
                        {widget.type === 'meta-ads' && <MetaAdsWidget />}
                        {widget.type === 'tiktok-ads' && <TikTokAdsWidget />}
                        {widget.type === 'social-insights' && <SocialInsightsWidget />}
                        {widget.type === 'music-insights' && <MusicInsightsWidget />}
                        {widget.type === 'fan-hub' && <FanHubWidget />}
                        {widget.type === 'marketing-assistant' && <AIGeneratorWidget />}
                      </div>
                    </>
                  </SortableItem>
                ))}
              </SortableContext>
            )}
          </DndContext>
        )}
      </Card>
    </div>
  );
};
