import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import { chartColors } from '@/constants/BrandColors';
import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useArtist from '@/hooks/artist/useArtist';
import useWidget from '@/hooks/widgets/useWidget';
import { ChartDataPieModel } from '@/models/Metrics';

import PieChartComponent from '../charts/PieChart';
import Loading from '../utility/Loading';
import Card from '../utility/microcomponents/Card';

export const MusicInsightsWidget = () => {
  const { t } = useTranslation();
  const { artist } = useArtist();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { widget, widgetIsLoading, widgetError } = useWidget({ type: 'music-insights' });
  const navigate = useNavigate();

  const [chartData, setChartData] = useState<ChartDataPieModel[]>();

  useEffect(() => {
    if (widget && widget.metrics) {
      const data = widget.metrics
        .filter((item) => item.percentage !== 0)
        .map((item) => {
          return {
            name: item.platform,
            value: item.percentage,
            fill: chartColors.pieChart.platforms[item.platform as keyof typeof chartColors.pieChart.platforms],
            metric: item.metric,
            actualValue: item.value,
          };
        });
      setChartData(data);
    }
  }, [widget]);

  useEffect(() => {
    if (!widgetIsLoading || widgetError) {
      setIsLoading(false);
    }
  }, [widgetIsLoading, widgetError]);

  return (
    <Card
      className="p10 widget-card music-insights pos-rel text-center cursor-pointer"
      onClick={() => {
        if (!artist) {
          return navigate('/profile', { state: { redirect: '/insights?tab=music&platform=overview' } });
        }
        navigate('/insights?tab=music&platform=overview');
      }}
    >
      {!chartData ||
        (chartData.length === 0 && !isLoading && (
          <img src={Images.widgetImages.insights} alt="insights" height={80} className="mt10" />
        ))}
      {!isLoading && chartData && chartData.length > 0 && (
        <PieChartComponent data={chartData} suffix="%" isLegend={false} isOverview={true} isWidget={true} />
      )}
      {isLoading && (
        <div className="d-flex jc-center mt10">
          <Loading size="small" />
        </div>
      )}
      {!artist && !isLoading && <img src={Images.widgetImages.insights} alt="insights" height={80} className="mt10" />}
      <div className="d-flex mt42 pos-abs b10 footer text-left">
        <div>
          <p className="fw-bold">{artist ? numberFormatter(widget?.totalValue || 0) : 'Connect Spotify'}</p>
          <p className="text-reduced-opacity small">{t('WIDGETS.MUSIC-FOLLOWERS')}</p>
        </div>
        <Button className="widget-button">
          <Icon>chevron_right</Icon>
        </Button>
      </div>
    </Card>
  );
};
